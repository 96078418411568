<template>
  <q-card style="max-width: 100%">
    <q-form ref="formRef" @submit.prevent="confirm" autocomplete="off" greedy>
      <q-card-section class="row items-center text-h6">{{
        title
      }}</q-card-section>

      <q-separator />

      <q-card-section class="q-pa-none">
        <slot name="form" />
      </q-card-section>

      <q-separator />

      <q-card-actions align="right">
        <q-btn
          flat
          label="Avbryt"
          @click="close"
          type="button"
          :disable="loadingState"
        />
        <q-btn
          label="Bekräfta"
          type="submit"
          color="primary"
          :loading="loadingState"
        />
      </q-card-actions>
    </q-form>
  </q-card>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue'
import { FormMode } from './MasterTable.vue'
import { QForm } from 'quasar'

export default defineComponent({
  name: 'MasterFormModal',

  props: {
    mode: String as PropType<FormMode>,
    loading: {
      type: Object,
      required: true,
    },
    add: {
      type: Function,
      required: true,
    },
    update: {
      type: Function,
      required: true,
    },
    formModel: {
      type: Object,
      required: true,
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    const formRef = ref<QForm | null>(null)

    function close() {
      emit('close')
    }

    const title = computed(() => {
      if (props.mode === 'update') {
        return 'Uppdatera'
      }
      if (props.mode === 'create') {
        return 'Lägg till'
      }
      return 'Kopiera'
    })

    const isUpdateMode = computed(() => {
      return props.mode === 'update'
    })

    async function confirm() {
      const isValid = await formRef?.value?.validate()

      if (!isValid) return

      if (isUpdateMode.value) {
        await props.update(props.formModel)
      } else {
        await props.add(props.formModel)
      }
      close()
    }

    const loadingState = computed(() => {
      return props.loading.create || props.loading.update
    })

    return { close, isUpdateMode, confirm, formRef, loadingState, title }
  },
})
</script>
