<template>
  <q-card style="min-width: 360px">
    <q-card-section class="row items-center text-h6">Ta bort</q-card-section>

    <q-separator />

    <q-card-section class="q-pa text-grey-8">
      Är du säker på att du vill ta bort {{ removeItemText }}?
    </q-card-section>

    <q-separator />

    <q-card-actions align="right">
      <q-btn
        flat
        label="Avbryt"
        @click="close"
        type="button"
        :disable="loading"
      />
      <q-btn
        label="Bekräfta"
        type="button"
        color="negative"
        @click="confirm"
        :loading="loading"
      />
    </q-card-actions>
  </q-card>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'MasterFormModal',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    remove: {
      type: Function,
      required: true,
    },
    formModel: {
      type: Object,
      required: true,
    },
    removeItemTextProp: {
      type: String,
      required: true,
    },
    removeIdentifier: {
      type: String,
      default: () => 'uuid',
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    function close() {
      emit('close')
    }

    async function confirm() {
      await props.remove(props.formModel[props.removeIdentifier])

      close()
    }

    const removeItemText = computed(() => {
      return props.formModel[props.removeItemTextProp]
    })

    return { close, confirm, removeItemText }
  },
})
</script>
