
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'MasterFormModal',

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    remove: {
      type: Function,
      required: true,
    },
    formModel: {
      type: Object,
      required: true,
    },
    removeItemTextProp: {
      type: String,
      required: true,
    },
    removeIdentifier: {
      type: String,
      default: () => 'uuid',
    },
  },

  emits: ['close'],

  setup(props, { emit }) {
    function close() {
      emit('close')
    }

    async function confirm() {
      await props.remove(props.formModel[props.removeIdentifier])

      close()
    }

    const removeItemText = computed(() => {
      return props.formModel[props.removeItemTextProp]
    })

    return { close, confirm, removeItemText }
  },
})
